@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.App {
  font-family: 'Montserrat';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  user-select: none !important;
}

/* common styles */
.Text-White {
  color: white;
}

.input {
  background-color: #dfe9f4;
  padding: 15px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 74%;
}

.label {
  text-align: left;
  padding-left: 80px;
  padding-bottom: 20px;
  line-height: 0px;
  font-weight: 700;
  font-size: 16px;
}

.Flex-Container {
  display: flex;
  padding: 0px;
  justify-content: space-between;
}

.Flex-Content {
  padding: 0px 20px;
}

.footer-text {
  text-align: left;
  color: whitesmoke;
}

.footer-input {
  background-color: #dfe9f4;
  padding: 14px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 60%;
}

.Flex-Display {
  display: flex;
}

.social-icons {
  display: flex;
  padding-top: 20px;
}

#error-page {
  text-align: center;
}

/* style for components */
.Header {
  background-color: #E3F1F1;
  padding: 40px 100px;
  display: flex;
  justify-content: space-around;
}

.Portfolio-Header {
  background-image: url('../public/assets/rectangle.png');
  /* background-repeat: repeat-x; */
  background-size: cover;
  padding-bottom: 30px;
}

.Portfolio-Header > .menu {
  display: flex;
  justify-content: space-around;
  padding: 40px 100px;
}

.Article-Header {
  background-image: url('../public/assets/article-banner.png');
  background-size: cover;
}

.Article-Header > .menu {
  display: flex;
  justify-content: space-around;
  padding: 40px 100px;
  margin-bottom: 380px;
}

.center {
  text-align: center;
  margin: auto;
}

.blur {
  /* margin-top: 200px; */
  background: rgba(228, 236, 236, 0.2);
  backdrop-filter: blur(22.5px);
}

.the {
  text-align: left;
}

.ojo {
  right: 20px;
}

.Opeyemi-Ojo-Text {
  line-height: 10.8px;
}

.bs-card {
  width: 750px;
  text-align: justify;
}

.Footer {
  padding: 20px 0px;
  background-color: #37B7B7;
  box-shadow: 0px 80.6px 105.701px rgba(51, 51, 51, 0.1);
  /* border-radius: 10px; */
}

.dropbox {
  float: left;
  margin-left: -170px;
  margin-top: -60px;
}

.dropbox-img {
  width: 200px;
}

.signature {
  width: 150px;
  padding-top: 30px;
}

.footer-btn {
  padding: 10px 35px 15px 30px;
  margin-top: 10px;
  font-weight: 600;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #D9D9D9;
  font-size: 12px;
}

.arrow {
  margin-bottom: -5px;
}

.Footer-inner-box {
  margin-bottom: 100px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  width: 50%;
  border-radius: 10px;
  background-color: #1B8B8B;
  box-shadow: 0px 80.6px 105.701px rgba(51, 51, 51, 0.1);
  /* border-radius: 10px; */
}

.Link > a {
  font-family: 'Manrope';
  text-decoration: none;
  color: #404040;
  padding: 25px;
}

.Link {
  margin-top: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Product {
  display: grid;
  width: 100%;
  place-items: center;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  padding: 10px
}

.margin {
  margin: 20px;
}

.margin2 {
  margin: 40px;
}

.logo {
  margin-top: 20px;
}

.Value-box {
  display: flex;
  margin: 10px;
  padding: 20px;
  justify-content: space-between;
}

.Value-banner {
  background-color: #f2f5ff;
  margin: 0px;
}

.text {
  font-size: 25px;
  /* text-indent: 50px; */
  line-height: 2.0;
}

.header1 {
  font-size: 85px;
  color: #054c73;
}

.header2 {
  font-size: 81px;
  font-weight: 800;
  font-family: 'Migra';
  font-style: normal;
}

.header4 {
  font-size: 44px;
  font-weight: 800;
  font-family: 'Migra';
  font-style: normal;
  text-align: left;
}

.header5 {
  font-size: 25px;
}

.button {
  background-color: #054c73;
  padding: 30px 80px;
  font-size: 16px;
  color: white;
  border-radius: 50px;
}

/* for mobile screens */
@media screen and (max-width: 750px) {
  .Flex-Container {
    display: block;
  }

  .Flex-Display {
    display: block;
    margin: auto;
  }

  .Article-Header > .menu {
    display: flex;
    justify-content: space-around;
    padding: 40px;
    margin-bottom: 280px;
  }

  .Portfolio-Header > .menu {
    display: flex;
    justify-content: space-around;
    padding: 40px;
  }

  .Header {
    background-color: #E3F1F1;
    padding: 40px 40px;
    display: flex;
    justify-content: space-between;
  }

  .dropbox {
    float: left;
    margin-left: -140px;
    margin-top: -50px;
  }

  .signature {
    width: 70px;
  }

  .dropbox-img {
    width: fit-content;
  }

  .footer-btn {
    padding: 12px 25px;
    font-weight: 600;
    border-radius: 6px;
    background-color: #D9D9D9;
    font-size: 12px;
  }

  .footer-input {
    background-color: #dfe9f4;
    padding: 15px;
    border-radius: 6px;
    width: 90%;
  }

  .signature {
    width: 120px;
  }

  .Footer-inner-box {
    margin-bottom: 100px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: 60%;
    border-radius: 10px;
    background-color: #1B8B8B;
    box-shadow: 0px 80.6px 105.701px rgba(51, 51, 51, 0.1);
    /* border-radius: 10px; */
  }
  
  .header2 {
    font-size: 41px;
    font-weight: 800;
    font-family: 'Migra';
    font-style: normal;
  }
  
  .header4 {
    font-size: 24px;
    font-weight: 800;
    font-family: 'Migra';
    font-style: normal;
  }
  
  .header5 {
    font-size: 20px;
  }

  .header1 {
    font-size: 35px;
    color: #054c73;
  }

  .App-background-card {
    width: 50%;
    border-radius: 20px;
    background-color: #dfe9f4;
    position: relative;
    display: block;
    float: right;
    padding: 50px;
    margin-right: 100px;
    margin-top: 100px;
  }

  .text {
    font-size: 20px;
    line-height: 1.8;
  }

  .button {
    background-color: #054c73;
    padding: 20px 70px;
    font-size: 20px;
    color: white;
    border-radius: 50px;
  }
}
