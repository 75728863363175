.Sidepane {
    background-color: #1B8B8B;
    padding: 92px 150px;
    min-height: 100vh;
}

.Vector-text {
    background-image: url('../../public/assets/Vector.png');
    background-repeat: no-repeat;
    height: 50px;
    width: auto;
    margin: 50px 0px;
}

.Vector-text > p {
    font-size: 9px;
    padding: 10px 10px;
}

/* Login Page */
.Login {
    margin: auto;
}

.margin-y-30 {
    margin: 50px 0px;
    padding: 40px 0px;
}

.Login > h3 {
    font-family: 'Migra';
    margin: 30px 0px;
    font-weight: 800;
    font-size: 55px;
}

label {
    color: #632121;
    font-family: 'Migra';
    margin: 0px 0px 0px 0px;
    font-weight: 800;
}

.Login-input {
    background-color: #dfe9f4;
    padding: 12px;
    width: 576px;
}

.Login > form > div > .button {
    margin: 40px 0px;
    width: 100%;
    cursor: pointer;
}

input:active {
    background-color: #35d0d0;
}

/* Register Page */
.Register {
    margin: auto;
}

.Register > h3 {
    font-family: 'Migra';
    margin: 20px 0px;
    font-weight: 800;
    font-size: 55px;
}

.Register > div > p > label {
    color: #1B8B8B;
    font-family: 'Migra';
    margin: 20px 0px;
    font-weight: 800;
}

.Register > form > div > .button {
    margin: 40px 0px;
    width: 100%;
    cursor: pointer;
}

.register-as:active {
    background-color: #35d0d0;
}

.checkbox {
    border: #dfe9f4;
    background-color: #1B8B8B;
    padding: 10px 20px;
    margin: 10px;
}

.checkbox > label {
    margin-right: 15px;
    color: black;
}

.Checkbox-Display {
    display: flex;
}

.txt {
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 750px) {
    .Sidepane {
        display: none;
        background-color: #1B8B8B;
        padding: 92px 120px;
        height: 100%;
        width: 100%;
    }

    .Register {
        margin: 30px 5px;
    }

    .Register > h3 {
        font-family: 'Migra';
        margin: 30px 0px;
        font-weight: 800;
        font-size: 35px;
        text-align: center;
    }

    .Login {
        margin: 100px 20px;
    }


    .Register > div > p > label {
        color: #1B8B8B;
        font-family: 'Migra';
        margin: 10px 0px;
        font-weight: 800;
    }

    .Login-input {
        background-color: #dfe9f4;
        width: 90%;
    }

    .checkbox {
        border: #dfe9f4;
        background-color: #1B8B8B;
        padding: 15px 20px;
        /* width: 50px; */
        margin: 5px;
    }

    .Checkbox-Display {
        display: flex;
    }
    
    .checkbox > label {
        margin-right: 5px;
        color: black;
    }

    .Register > div > .button {
        /* margin: 40px 20px; */
        width: 100%;
    }

    .Login > div > .button {
        /* margin: 20px 20px; */
        width: 100%;
    }

    .Login > h3 {
        font-family: 'Migra';
        margin: 30px 0px;
        font-weight: 800;
        font-size: 35px;
        text-align: center;
    }
}
